<template>
  <base-view title="Pagos sin comprobante" icon="file-minus">
    <div>
      <check-authorization :requiresAuthorizations="['indice pagos sin comprobante']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Pagos sin comprobante fiscal

            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <!-- Form -->
              <check-authorization
                :requiresAuthorizations="['crear pagos sin comprobante']"
                :overrideIf="$store.getters['pagosSinComprobanteModule/isEditingResource']"
              >
                <pagos-sin-comprobante-form
                  :value="showForm || isEditingResource"
                  @input="showForm = $event"
                />
              </check-authorization>
              <!-- Search -->
              <search-bar
                placeholder="Buscar por inversionista, departamento, período, fecha de pago o archivo"
                @filter="(filter) => this.filter = filter"></search-bar>
              <!-- Options -->
              <div class="dropdown no-caret">
                <button
                  class="btn btn-transparent-dark btn-icon dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
                  <a class="dropdown-item my-cursor" @click="getResource(null)">
                    Recargar
                  </a>
                </div>
              </div>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <pagos-sin-comprobante-list @on-got-item="showForm = true" :filter="filter"></pagos-sin-comprobante-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SearchBar from '../../components/Common/Views/SearchBar.vue'
import PagosSinComprobanteForm from '@/components/Operaciones/PagosSinComprobante/PagosSinComprobanteForm'
import PagosSinComprobanteList from '@/components/Operaciones/PagosSinComprobante/PagosSinComprobanteList'

export default {
  name: 'PagosSinComprobanteMainView',

  components: {
    PagosSinComprobanteForm,
    PagosSinComprobanteList,
    SearchBar
  },

  data () {
    return {
      hideButton: false,
      showForm: false,
      filter: ''
    }
  },

  computed: mapGetters('pagosSinComprobanteModule', ['isEditingResource']),

  methods: {
    ...mapActions('pagosSinComprobanteModule', ['getResource'])
  }
}
</script>
