<template>
  <div>
    <slot name="activator" v-bind:onShowModal="onShowModal">
      <b-button @click="onShowModal" variant="success" size="sm">Crear</b-button>
    </slot>

    <b-modal
      v-model="modal_inversionistas"
      centered
      size="lg"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span>Seleccionar destinatarios...</span>
      </template>

      <b-card no-body>
        <b-card-header>
          <span class="mb-2">Listado de inversionistas</span>
          <div class="ml-auto d-flex justify-content-end align-items-center">
            <b-form-select v-model="empresa_id" :options="options" size="sm" class="w-100 mx-2" style="max-width: 400px"
                           :disabled="loading_options" @change="onChangeOption"
            ></b-form-select>
            <search-bar
              placeholder="Buscar por rfc, correo o razón social"
              @filter="(filter) => this.filter = filter"></search-bar>
          </div>
        </b-card-header>
        <b-card-body>
          <b-overlay :show="loading_inversionistas">
            <template #overlay>
              <div class="text-center">
                <loading message="Obteniendo información, por favor espere..." />
              </div>
            </template>
            <div>
              <b-table
                id="mensajesTable"
                :fields="fields"
                :items="inversionistas"
                sticky-header
                striped
                bordered
                responsive
                hover
                show-empty
                small
                :filter="filter"
                empty-text="No se encontraron inversionistas con departamentos asignados a la empresa o desarrollo seleccionados"
                empty-filtered-text="No se encontraron inversionistas con los parámetros de búsqueda escritos"
              >
                <template #cell(rfc)="row">
                  <filtered-row :value="row.value" :filter="filter"/>
                </template>
                <template #cell(email)="row">
                  <filtered-row :value="row.value" :filter="filter"/>
                </template>
                <template #cell(identificacion)="row">
                  <filtered-row :value="row.value" :filter="filter"/>
                </template>
                <template #cell(selected)="row">
                  <b-form-checkbox v-model="row.value" @change="selectRow(row.item)" switch inline class="user-select-none">
                  </b-form-checkbox>
                </template>
              </b-table>
            </div>
          </b-overlay>
        </b-card-body>
      </b-card>
    </b-modal>

    <b-modal
      v-model="modal"
      centered
      size="lg"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span :class="isEditingResource ? 'text-danger' : ''">{{ showFormTitle }}</span>
      </template>

      <x-alert-with-errors
        :error="response.error"
        :title="response.message"
        :errors="response.errors"
      />

      <!-- <b-row v-if="isEditingResource">
        <b-col class="d-flex flex-row">
          <b-badge variant="dark" class="mb-3 mr-2" v-text="getLastAccessText"></b-badge>
          <b-badge variant="success" class="mb-3 mr-2" v-text="getCreationDate"></b-badge>
          <b-badge variant="warning" class="mb-3" v-text="getModificationDate"></b-badge>
        </b-col>
      </b-row> -->

      <b-overlay :show="isLoading">
        <template #overlay>
          <div class="text-center">
            <loading message="Procesando información, por favor espere..." />
          </div>
        </template>

        <FormulateForm
          ref="pagosSinComprobanteForm"
          name="pagosSinComprobanteForm"
          v-model="formValues"
          :errors="response.errors"
          @submit="submit"
        >
          <div class="card">
            <div class="card-header">
              Información del pago a registrar
            </div>
            <div class="card-body">
              <FormulateInput
                name="inversionista_id"
                validation="bail|required"
              >
                <template #element>
                  <span class="text-black small font-weight-bolder mb-3">
                    Seleccionar inversionista
                    <span class="text-danger">*</span>
                  </span>
                  <!--pagos-sin-comprobante-inversionista-modal v-model="formValues.inversionista_id" @change="onChangeInversionista"></pagos-sin-comprobante-inversionista-modal-->
                  <div class="mb-2">
                    <b-button @click="onShowModalInversionistas" variant="success" size="sm">Seleccionar</b-button>
                    <span class="ml-2" v-if="selected.length > 0">{{ selected.length }} seleccionados</span>
                    <span class="ml-2" v-else>No hay seleccionados</span>
                  </div>
                  <div v-if="selected.length > 0">
                    <b-table
                      id="seleccionadosTable"
                      :fields="[{ key: 'identificacion', label: 'Seleccionados' }]"
                      :items="selected"
                      sticky-header
                      striped
                      bordered
                      responsive
                      hover
                      show-empty
                      small
                      :filter="filter"
                    >
                      <template #cell(identificacion)="row">
                        <span class="ml-1 mb-1 my-cursor text-black small" @click="selectRow(row.item)">{{ `${row.item.identificacion} (${row.item.email})` }}</span>
                      </template>
                    </b-table>
                  </div>
                </template>
              </FormulateInput>

              <FormulateInput
                name="tipo_pago"
                validation="bail|required"
                label="Tipo de pago"
                type="select"
                :options="{Arrendamiento: 'Arrendamiento', Rendimiento: 'Rendimiento'}"
                >

              </FormulateInput>
              <b-row>
                <b-col cols="12" md="6">
                  <FormulateInput
                    name="departamento_id"
                    validation="bail|required">
                    <template #element>
                      <x-form-group title="Departamento" required>
                        <x-select
                          v-model="formValues.departamento_id"
                          dispatchPath="inversionistaModule/getSelectDeptos"
                          getterListPath="inversionistaModule/getDeptosData"
                          :formatListWith="['id', 'identificacion']"
                          :disabled="isLoading"
                          @input="onChangeDepto"
                        >
                        </x-select>
                      </x-form-group>
                    </template>
                  </FormulateInput>
                </b-col>

                <b-col cols="12" md="6">
                  <FormulateInput
                    name="periodo_id"
                    validation="bail|required">
                    <template #element>
                      <x-form-group title="Período" required>
                        <x-select
                          v-model="formValues.periodo_id"
                          dispatchPath="periodoModule/getList"
                          getterListPath="periodoModule/getDataList"
                          :formatListWith="['id', 'descriptor']"
                          :disabled="isLoading"
                        >
                        </x-select>
                      </x-form-group>
                    </template>
                  </FormulateInput>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="6">
                  <FormulateInput
                    name="importe"
                    label="Importe"
                  />
                </b-col>

                <b-col cols="12" md="6">
                  <FormulateInput
                    name="fecha_pago"
                    label="Fecha de pago (dd/mm/yyyy)"
                    type="text"
                  />
                </b-col>
              </b-row>

              <b-form-group>
                <b-form-file v-model="files" :state="Boolean(files.length)" multiple plain></b-form-file>
              </b-form-group>
            </div>
          </div>
        </FormulateForm>
      </b-overlay>

      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="$refs.inversionistaForm ? $refs.inversionistaForm.hasErrors : false"
            :loading="isLoading"
            :isEditing="isEditingResource"
            :isCreating="!isEditingResource"
            @on-cancel="resetForm"
            @on-create="submit"
            @on-update="submit"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SearchBar from '../../Common/Views/SearchBar.vue'
import FilteredRow from '../../Common/Table/FilteredRow.vue'

const formValues = () => ({
  importe: '',
  fecha_pago: '',
  descripcion: '',
  departamento_id: '',
  inversionista_id: '',
  tipo_pago: '',
  periodo_id: '',
  archivo: []
})

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

const API_MODULE = 'pagosSinComprobanteModule'

export default {
  name: 'PagosSinComprobanteForm',

  components: {
    SearchBar,
    FilteredRow
  },

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  mounted () {
    this.init()
  },

  data () {
    return {
      modal: this.value,

      isLoading: false,

      formValues: formValues(),

      response: response(),

      modal_inversionistas: false,

      loading_inversionistas: false,

      fields: [
        { key: 'rfc', label: 'RFC' },
        { key: 'email', label: 'Correo' },
        { key: 'identificacion', label: 'Razón social' },
        { key: 'selected', label: 'Seleccionado' }
      ],

      inversionistas: [],

      filter: '',

      selected: [],

      selectAll: false,

      empresa_id: null,
      condominio_id: null,
      options: [],
      loading_options: true
    }
  },

  computed: {
    //
    // User module
    ...mapState(API_MODULE, ['editingResource']),
    ...mapGetters(API_MODULE, ['isEditingResource', 'getSelectedDeptoData']),

    showFormTitle () {
      return this.isEditingResource ? 'Editar pago' : 'Crear pago'
    },

    //
    // Guarda y obtiene la información de Vuex
    files: {
      get () {
        return this.$store.state.pagosSinComprobanteModule.importFiles.files
      },
      set (value) {
        this.$store.commit('pagosSinComprobanteModule/setFilesToImport', value)
      }
    }
  },

  watch: {
    isEditingResource: {
      async handler (value) {
        if (value) {
          const { id, importe, fecha_pago, tipo_pago, descripcion, departamento, inversionista, periodo, archivo } = this.editingResource
          this.formValues.id = id
          this.formValues.importe = importe
          this.formValues.fecha_pago = fecha_pago
          this.formValues.tipo_pago = tipo_pago
          this.formValues.descripcion = descripcion
          this.formValues.departamento_id = departamento.id
          this.formValues.inversionista_id = inversionista.id
          this.formValues.periodo_id = periodo.id
          if (archivo) this.formValues.archivo_id = archivo.id
          // Load additional information

          this.selected = []
          const item = this.inversionistas.find(row => row.id === inversionista.id)
          item.selected = true
          this.selected.push(item)

          await this.$store.dispatch('inversionistaModule/getSelectDeptos', inversionista.id)
          return
        }

        this.resetForm()
      }
    },

    value: {
      immediate: true,
      handler (value) {
        this.modal = value
      }
    },

    modal: {
      immediate: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    async init () {
      this.isLoading = true
      this.loading_inversionistas = true
      const response = await this.$store.dispatch('inversionistaModule/getSelectListAll')
      // Inicialmente no filtramos la información
      this.inversionistas = response.data.map((item) => {
        return {
          id: item.id,
          rfc: item.rfc,
          email: item.email,
          identificacion: item.identificacion,
          selected: false
        }
      })
      this.loading_inversionistas = false
      this.isLoading = false

      this.loading_options = true
      this.load_options()
      this.loading_options = false
    },

    async load_options () {
      // Load options empresa
      const { data } = await this.$store.dispatch('empresaModule/getSelectTree')
      const options = [{ value: null, text: '-- Seleccione una empresa o desarrollo -- ' }]
      for (var i = 0; i < data.length; i++) {
        options.push({ value: { empresa_id: data[i].id, condominio_id: null }, text: `Empresa: ${data[i].razon_social}` })
        for (var j = 0; j < data[i].condominios.length; j++) {
          options.push({ value: { empresa_id: data[i].id, condominio_id: data[i].condominios[j].id }, text: `-- Desarrollo: ${data[i].condominios[j].nombre}` })
        }
      }
      this.options = options
    },

    async onChangeOption (value) {
      this.loading_inversionistas = true
      this.inversionistas = []
      this.selected = []
      // La información se filtrará de acuerdo al parámetro elegido por el usuario en el selector de empresas y desarrollos
      const response = await this.$store.dispatch('inversionistaModule/getSelectListAll', value)
      this.inversionistas = response.data.map((item) => {
        return { id: item.id, rfc: item.rfc, email: item.email, identificacion: item.identificacion, selected: false }
      })
      this.loading_inversionistas = false
    },

    selectRow (item) {
      // If it's actually selected remove
      if (this.isDuplicate(item)) {
        this.removeItem(item)
        this.inversionistas.find(row => row.id === item.id).selected = false
        return
      }

      // Delete previous selected because in this modal only one inversionista must be selected
      this.selected = []
      this.selected.push(item)
      this.inversionistas.find(row => row.id === item.id).selected = true
      this.onChangeInversionista(item.id)
    },

    /**
     * Valida si el inversionista ya se encuentra seleccionado
     */
    isDuplicate (item) {
      return this.selected.map(item => item.id).includes(item.id)
    },

    /**
     * Valida si el inversionista que se muestra ya está en el array selected,
     * si es así entonces devuelve el nombre de una clave para aplicarla.
     *
     */
    isSelected (item) {
      return this.selected.map(item => item.id).includes(item.id) ? 'is__selected' : ''
    },

    /**
     * Elimina un elemento de los que están seleccionados.
     */
    removeItem (item) {
      this.selected = this.selected.filter(el => el.id !== item.id)
    },

    /**
     * Envía el formulario.
     *
     * Envía el formulario para su creación o su actualización.
     * Dependiendo si la variable "isEditingResource" es verdadera actualizará
     * si no es así entonces mandará crear.
     */
    async submit () {
      this.response = response()
      this.prepareInput()
      console.log(this.formValues)

      this.isLoading = true
      const { error, message, data } = this.isEditingResource
        ? await this.updatePagoSinComprobante(this.formValues)
        : await this.createPagoSinComprobante(this.formValues)
      this.isLoading = false

      this.$notify({ error, message }, 'Envío de formulario')

      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}

      if (!error) this.resetForm()
    },

    async createPagoSinComprobante (form) {
      return await this.$store.dispatch(
        `${API_MODULE}/createResource`,
        { form: form, files: this.$store.getters['pagosSinComprobanteModule/getImportFiles'] }
      )
    },

    async updatePagoSinComprobante (form) {
      return await this.$store.dispatch(`${API_MODULE}/updateResource`, {
        id: form.id,
        payload: form
      })
    },

    /**
     * Prepara los datos antes de enviarlos al backend.
     *
     * Prepara los datos necesarios como la fecha o los roles y permisos que
     * se deben procesar en el backend.
     */
    prepareInput () {
    },

    resetForm () {
      document.activeElement.blur()
      this.$store.commit(`${API_MODULE}/setResourceToEdit`, null)
      this.$formulate.reset('pagosSinComprobanteForm')
      this.formValues = formValues()
      this.response = response()

      this.modal = false
    },

    onShowModal () {
      this.modal = true
      this.$emit('input', this.modal)
    },

    onShowModalInversionistas () {
      this.modal_inversionistas = true
    },

    async onChangeInversionista (inversionista_id) {
      // Obtenemos listado de departamentos de acuerdo al inversionista seleccionado
      this.formValues.inversionista_id = inversionista_id
      this.formValues.departamento_id = ''
      this.formValues.periodo_id = ''
      this.formValues.importe = ''
      await this.$store.dispatch('inversionistaModule/getSelectDeptos', inversionista_id)
    },

    async onChangeDepto ($event) {
      // Obtenemos importe del departamento
      const res = await this.$store.dispatch('inversionistaModule/getSelectedDepto', $event)
      if (res && res.data) {
        this.formValues.importe = parseFloat(res.data.importe).toFixed(2)
        this.formValues.periodo_id = res.data.periodo_id
      }
    }
  }
}
</script>

<style>
.required_form {
  color: red;
}
.isLoading {
  max-height: 400px !important;
  overflow: hidden;
}
</style>
